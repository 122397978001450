import axios from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import {
  demoGetAllIntegrations,
  demoGetAllIntegrationsV2,
  demoGetAllNewIntegrations,
} from 'api/mockResponses/demo/integrations.mock'
import { getBaseUrl } from 'api/utils'
import Demo from 'configs/demo'
import { LoggerService } from 'services/LogService/LogService'
import { mockedIntegration, mockedIntegrations } from 'store/integrations/integrations.store.mocks'

import type {
  Integration,
  ConnectionPost,
  IntegrationNew,
  IntegrationParam,
  SalesforceOpportunity,
  MarketoAction,
  IntegrationV2,
} from 'models/Integration.model'

export const post = async (id: string, integration: ConnectionPost) => {
  try {
    const { data } = await axios.post<Integration>(`${getBaseUrl('INTEGRATIONS_API')}/integrations/${id}`, integration)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'post integration error', error })
    return error
  }
}

export const getAll = async () => {
  try {
    const { data } = await axios.get<Integration[]>(`${getBaseUrl('CORE_API')}/v1/core/integrations`, {
      demoData: demoGetAllIntegrations,
    } as DemoAxiosRequestConfig)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getAll integrations error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch connections')
    }
  }
}

export const get = async (id: string) => {
  try {
    const { data } = await axios.get<Integration>(`${getBaseUrl('CORE_API')}/v1/core/integrations/${id}`)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get integration error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch connection')
    }
  }
}

export const update = async (app: string, connectionId: string, params: any): Promise<Integration> => {
  try {
    const { data } = await axios.put<Integration>(
      `${getBaseUrl('CORE_API')}/v1/core/integrations/${app}/${connectionId}`,
      params,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'update integration error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch connection')
    }
  }
}

export const remove = async (connectionId: string) => {
  try {
    const { data } = await axios.delete<Integration>(`${getBaseUrl('INTEGRATIONS_API')}/integrations/${connectionId}`)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'remove integration error', error })
    return []
  }
}

export const getActionsData = async (
  app: string,
  connectionId: string,
): Promise<SalesforceOpportunity[] | MarketoAction> => {
  try {
    const { data } = await axios.get<SalesforceOpportunity[] | MarketoAction>(
      `${getBaseUrl('INTEGRATIONS_API')}/testConnection/${app}/${connectionId}`,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getActionsData integration error', error })
    return []
  }
}

/**
 *  Get all integrations from the data-core service.
 * @returns {IntegrationNew[]} The fetched integrations.
 */
export const getAllNew = async () => {
  try {
    if (Demo.mockApiIsEnabled()) {
      return mockedIntegrations
    } else {
      const { data } = await axios.get<IntegrationNew[]>(`${getBaseUrl('CORE_API')}/v2/core/integrations`, {
        demoData: demoGetAllNewIntegrations,
      } as DemoAxiosRequestConfig)
      return data
    }
  } catch (error: unknown) {
    LoggerService.error({ message: 'getAll integrations error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch connections')
    }
  }
}

/**
 * Get a single integration from the data-core service.
 * @param id {string} The id of the integration to fetch.
 * @param options The parameters of the integration to fetch.
 * @returns {IntegrationNew} The fetched integration.
 */
export const getNew = async (id: string, options?: IntegrationParam) => {
  try {
    if (Demo.mockApiIsEnabled()) {
      return mockedIntegrations.find((integration) => integration.key === id) || mockedIntegration
    } else {
      const { data } = await axios.get<IntegrationNew>(`${getBaseUrl('CORE_API')}/v2/core/integrations/${id}`, {
        params: options,
      })
      return data
    }
  } catch (error: unknown) {
    LoggerService.error({ message: 'get integration error', error: error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch connection')
    }
  }
}

/**
 * Create an integration in the data-core service.
 * @param {string} id The type of integration to create.
 * @param {object} payload The payload to send to the API to create the integration.
 * @returns {IntegrationNew} The created integration.
 */
export const postNew = async (id: string, payload: any) => {
  try {
    const { data } = await axios.post<IntegrationNew>(`${getBaseUrl('CORE_API')}/v2/core/integrations/${id}`, payload)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'postNew integration error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to create connection')
    }
  }
}

export const getIntegrationsV2 = async () => {
  try {
    const { data } = await axios.get<{ platforms: IntegrationV2[] }>(
      `${getBaseUrl('CORE_API')}/v2/core/integrations/connections`,
      {
        demoData: demoGetAllIntegrationsV2,
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get integration connnections error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch integration connections')
    }
  }
}
