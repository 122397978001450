import { Dropdown } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Heading } from 'components/common'
import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import Sandbox from 'configs/sandbox'
import { getIconUrl } from 'pages/Integrations/utils'
import { AddConnectionModal } from 'pages/IntegrationsV2/AddConnectionModal/AddConnectionModal'
import { AvailableConnections } from 'pages/IntegrationsV2/AvailableConnections/AvailableConnections'
import { ConnectionsTable } from 'pages/IntegrationsV2/ConnectionsTable/ConnectionsTable'
import { StatCard } from 'pages/IntegrationsV2/StatCard/StatCard'
import {
  getActionsExecutedStatCardData,
  getLatestIngestStatCardData,
  getLiveConnectionsStatCardData,
} from 'pages/IntegrationsV2/utils'
import useStore from 'store/useStore'

import type { MenuProps } from 'antd'

export const IntegrationsV2 = observer(() => {
  const { integrationsV2: integrationsV2Flag } = useFlags<LaunchDarklyFeatureFlags>()
  const navigate = useNavigate()
  const { integrationsStore } = useStore()
  const { integrationsV2, getIntegrationsV2, isLoading } = integrationsStore

  const defaultAddConnectionModalItems = {
    isVisible: false,
    selectedConnectionDisplayName: '',
    selectedConnectionId: '',
  }
  const [addConnectionModalItems, setAddConnectionModalItems] = useState(defaultAddConnectionModalItems)

  // Redirect to integrations page if the feature flag is not enabled
  // Temporary until feature flag is removed
  useEffect(() => {
    if (!integrationsV2Flag) {
      navigate(`/integrations`)
    }
  }, [integrationsV2])

  useEffect(() => {
    getIntegrationsV2().catch((error) => {
      console.error('Error fetching integration connections', error)
    })
  }, [])

  const liveConnectionsStatCardData = useMemo(() => getLiveConnectionsStatCardData(integrationsV2), [integrationsV2])
  const latestIngestStatCardData = useMemo(() => getLatestIngestStatCardData(integrationsV2), [integrationsV2])
  const actionsExecutedStatCardData = useMemo(() => getActionsExecutedStatCardData(integrationsV2), [integrationsV2])

  const getAddConnectonsDropdownItems = () => {
    if (!integrationsV2) {
      return []
    }
    return integrationsV2.map((integration, index) => {
      return {
        label: (
          <div key={integration.id} className='integrations__platform'>
            <DynamicImage key={integration.id} iconUrl={getIconUrl(integration.id)} iconAlt={integration.displayName} />
            <span>{integration.displayName}</span>
          </div>
        ),
        key: index.toString(),
        onClick: () => {
          setAddConnectionModalItems({
            isVisible: true,
            selectedConnectionDisplayName: integration.displayName,
            selectedConnectionId: integration.id,
          })
        },
      }
    })
  }

  const items: MenuProps['items'] = useMemo(() => getAddConnectonsDropdownItems(), [integrationsV2])

  return (
    <main className='integrations'>
      <div className='integrations__header'>
        <div className='container integrations__header__container'>
          <Heading variant='2' level='2' className='integrations__header__title'>
            Integrations
          </Heading>
          <Dropdown placement='bottomRight' trigger={['click']} menu={{ items }}>
            <div>
              <Button text='Add Connection' size='L' disabled={Sandbox.isEnabled() || isLoading || !integrationsV2} />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className='container'>
        <div className='content content__spacing--lg'>
          <section className='integrations__stat-cards'>
            <Heading variant='1' level='1' className='integrations__stat-cards__title'>
              Your data working together
            </Heading>

            <div className='integrations__stat-cards__list'>
              <StatCard {...liveConnectionsStatCardData} />
              <StatCard {...latestIngestStatCardData} />
              <StatCard {...actionsExecutedStatCardData} />
            </div>
          </section>

          <section className='integrations__connections'>
            <div className='integrations__connections__heading'>
              <Heading variant='2' level='2' className='integrations__title'>
                Your Connections
              </Heading>
              <Dropdown placement='bottomRight' trigger={['click']} menu={{ items }}>
                <div>
                  <Button
                    text='Add Connection'
                    size='L'
                    type='secondary'
                    disabled={Sandbox.isEnabled() || isLoading || !integrationsV2}
                  />
                </div>
              </Dropdown>
            </div>

            <ConnectionsTable />
          </section>

          <section className='integrations__available-connections'>
            <Heading variant='2' level='2' className='integrations__title integrations__available-connections__title'>
              Available Connections
            </Heading>

            <AvailableConnections setAddConnectionModalItems={setAddConnectionModalItems} />
          </section>
        </div>
      </div>
      <AddConnectionModal
        selectedConnectionDisplayName={addConnectionModalItems.selectedConnectionDisplayName}
        selectedConnectionId={addConnectionModalItems.selectedConnectionId}
        isVisable={addConnectionModalItems.isVisible}
        handleCancel={() => setAddConnectionModalItems(defaultAddConnectionModalItems)}
      />
    </main>
  )
})
IntegrationsV2.displayName = 'IntegrationsV2'
