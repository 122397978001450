import chorusaiIcon from 'assets/icons/integrations/chorusai.png'
import gainsightIcon from 'assets/icons/integrations/gainsight.png'
import hubspotIcon from 'assets/icons/integrations/hubspot.png'
import intercomIcon from 'assets/icons/integrations/intercom.png'
import marketoIcon from 'assets/icons/integrations/marketo.png'
import mongodbIcon from 'assets/icons/integrations/mongodb.png'
import netsuiteIcon from 'assets/icons/integrations/netsuite.png'
import pendoIcon from 'assets/icons/integrations/pendo.png'
import redshiftIcon from 'assets/icons/integrations/redshift.png'
import salesforceIcon from 'assets/icons/integrations/salesforce.png'
import shopifyIcon from 'assets/icons/integrations/shopify.png'
import slackIcon from 'assets/icons/integrations/slack.png'
import snowflakeIcon from 'assets/icons/integrations/snowflake.png'
import zendeskIcon from 'assets/icons/integrations/zendesk.png'

/**
 * Return the icon for a given integration key.
 * @param {string} key The integration key.
 * @returns The icon URL.
 */
export function getIconUrl(key: string) {
  switch (key) {
    case 'hubspot':
      return hubspotIcon
    case 'gainsight':
      return gainsightIcon
    case 'intercom':
      return intercomIcon
    case 'marketo':
      return marketoIcon
    case 'mongodb':
      return mongodbIcon
    case 'netsuite':
      return netsuiteIcon
    case 'pendo':
      return pendoIcon
    case 'redshift':
      return redshiftIcon
    case 'salesforce':
      return salesforceIcon
    case 'shopify':
      return shopifyIcon
    case 'slack':
      return slackIcon
    case 'snowflake':
    case 'snowflakedb':
      return snowflakeIcon
    case 'zendesk':
    case 'zendesk-support':
      return zendeskIcon
    case 'chorusai':
      return chorusaiIcon
    default:
      console.warn('Unknown Icon Key:', key)
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
  }
}
