import { Form } from '@rjsf/antd'
import validator from '@rjsf/validator-ajv8'
import { Modal, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, type NavigateFunction } from 'react-router-dom'

import { Button } from 'components/common'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { IChangeEvent } from '@rjsf/core'

import type { RJSFSchema, UiSchema } from '@rjsf/utils/lib/types'

interface AddConnectionModalProps {
  selectedConnectionDisplayName: string
  selectedConnectionId: string
  isVisable: boolean
  handleCancel: () => void
}

const uiSchema: UiSchema = {
  token: {
    'ui:widget': 'password',
    'ui:autocomplete': 'off',
  },
  password: {
    'ui:widget': 'password',
    'ui:autocomplete': 'off',
  },
  oauthTemplateUrl: {
    'ui:widget': 'hidden',
    'ui:autocomplete': 'off',
  },
}

export const onSubmit =
  (
    type: string,
    code: string | null,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    postNew: (id: string, data: Record<string, string | Record<string, string>>) => Promise<Record<string, string>>,
    navigate: NavigateFunction,
  ) =>
  async (
    data: IChangeEvent<Record<string, string>, RJSFSchema, any>,
    _event: React.FormEvent<Record<string, string>>,
  ) => {
    setIsLoading(true)

    const configuration: Record<string, string> = {
      ...data.formData,
    }

    // Redirect to OAuth based on the constructed URL from the form data
    if (configuration.oauthTemplateUrl && !code) {
      // Pull out the template we want to fill in with our form data and replace all the variables in the oauthTemplate
      const oauthLink: string = configuration.oauthTemplateUrl.replace(
        /###(\w+)###/g,
        (match: string, key: string): string => configuration[key] || match,
      )

      // Redirect
      window.location.assign(oauthLink)
      return
    }

    // Clean up oauthTemplate
    delete configuration.oauthTemplateUrl

    // Extract displayName from the payload to add to the top level.
    const { displayName } = configuration
    delete configuration.displayName

    if (code) {
      configuration.temporaryCode = code
    }

    let output
    try {
      output = await postNew(type, {
        displayName,
        configuration,
      })
    } catch (error: unknown) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }

    if (output) {
      localStorage.removeItem(`${type}-formData`)
      navigate('/integrations')
    }
  }

export const AddConnectionModal = observer(
  ({ selectedConnectionDisplayName, selectedConnectionId, isVisable, handleCancel }: AddConnectionModalProps) => {
    const { integrationsStore } = useStore()
    const { currentIntegrationNew: currentIntegration, getNew, postNew, isLoading: isGetNewLoading } = integrationsStore
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({})

    // Load saved data from localStorage when the component mounts
    useEffect(() => {
      const savedData = localStorage.getItem(`${currentIntegration?.key}-formData`)
      if (savedData) {
        setFormData(JSON.parse(savedData) as object)
      }
    }, [currentIntegration])

    const [queryParameters] = useSearchParams()
    const code = queryParameters.get('code')

    useEffect(() => {
      getNew(selectedConnectionId, { code }).catch(console.error)
    }, [selectedConnectionId])

    useDisplayErrorNotification(integrationsStore)

    // Loop over properties and add uiSchema for each property that isSecret
    if (currentIntegration?.fieldConfig?.allOf) {
      for (const item of currentIntegration.fieldConfig.allOf) {
        if (item?.properties) {
          for (const [key, value] of Object.entries(item.properties)) {
            if (value && value.isSecret) {
              uiSchema[key] = {
                'ui:widget': 'password',
              }
            }
          }
        }
      }
    }

    // Update the state and save the form data to localStorage onChange, until we submit.
    const onChange = (data: IChangeEvent<Record<string, string>, RJSFSchema, any>, id?: string) => {
      const savedData =
        (JSON.parse(localStorage.getItem(`${currentIntegration?.key}-formData`) || '{}') as object) || {}
      const processData = { ...savedData, ...data.formData }
      setFormData(processData)
      localStorage.setItem(`${currentIntegration?.key}-formData`, JSON.stringify(processData))
    }

    return (
      <Modal
        title={`Connect to ${selectedConnectionDisplayName}`}
        open={isVisable}
        onCancel={handleCancel}
        centered
        width={668}
        className='add-connection-modal'
        footer={false}>
        <div className='add-connection-modal__body'>
          <Spin spinning={isGetNewLoading} size='large'>
            {currentIntegration && (currentIntegration.fieldConfig || currentIntegration.oauthLink) && (
              <>
                {currentIntegration?.oauthLink ? (
                  <a
                    href={currentIntegration.oauthLink}
                    className='button button-primary button-XL'
                    data-testid='oauth-button'>
                    Connect to {currentIntegration.displayName}
                  </a>
                ) : (
                  <Form
                    formData={formData}
                    onChange={onChange}
                    onSubmit={onSubmit(currentIntegration?.key ?? '', code, setIsLoading, postNew, navigate)}
                    schema={currentIntegration?.fieldConfig as RJSFSchema}
                    uiSchema={uiSchema}
                    validator={validator}
                    autoComplete='off'>
                    <div className='add-connection-modal__footer'>
                      <Button
                        key='cancel'
                        text='Cancel'
                        type='secondary'
                        testId='cancel-btn'
                        onClickHandler={handleCancel}
                      />
                      <Button
                        key='submit'
                        className='m-l-10'
                        disabled={isLoading}
                        text={typeof code === 'string' ? `Connect to ${currentIntegration?.displayName}` : 'Create'}
                        htmlType='submit'
                      />
                    </div>
                  </Form>
                )}
              </>
            )}
          </Spin>
        </div>
      </Modal>
    )
  },
)
AddConnectionModal.displayName = 'AddConnectionModal'
