import dayjs from 'dayjs'

import { IconCheckCircle, IconInfo, IconProcessing } from 'components/common'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import { DataFormatEnum } from 'models/insights'
import type { IntegrationV2, IntegrationV2Connection } from 'models/Integration.model'

export const getLiveConnectionsStatCardData = (integrationConnections: IntegrationV2[]) => {
  if (!integrationConnections || integrationConnections.length === 0) {
    return {
      title: 'Live Connections',
      integrations: [],
      text: '-',
    }
  }
  const liveConnections = integrationConnections.filter(
    (integration) => integration.connections && integration.connections.length,
  )

  return {
    title: 'Live Connections',
    integrations: liveConnections,
    text: liveConnections.length ? liveConnections.length.toString() : '-',
  }
}

export const getLatestConnection = (connections: IntegrationV2Connection[]) => {
  if (connections.length === 1) {
    return connections[0]
  }

  return connections.reduce(
    (acc, connection) => {
      if (!connection.lastIngestedDate) return acc
      if (!acc || connection.lastIngestedDate > acc.lastIngestedDate) {
        return connection
      }
      return acc
    },
    { ...connections[0] } as IntegrationV2Connection,
  )
}

export const getIntegrationWithLatestConnection = (integrationConnections: IntegrationV2[]) => {
  return integrationConnections.reduce((acc, integration) => {
    if (!integration.connections) return acc

    const latestConnection = getLatestConnection(integration.connections)

    if (!acc || !acc.connections || latestConnection.lastIngestedDate > (acc.connections[0]?.lastIngestedDate ?? 0)) {
      return {
        ...integration,
        connections: [latestConnection],
      }
    }
    return acc
  }, integrationConnections[0])
}

export const getLatestIngestStatCardData = (integrationConnections: IntegrationV2[]) => {
  if (
    !integrationConnections ||
    integrationConnections.length === 0 ||
    !getIntegrationsWithConnections(integrationConnections).length
  ) {
    return {
      title: 'Lastest Ingest',
      integrations: [],
      text: '-',
    }
  }

  const latestIngestDateConnection = getIntegrationWithLatestConnection(integrationConnections)

  const formattedDate =
    !latestIngestDateConnection.connections || !latestIngestDateConnection.connections.length
      ? '-'
      : dayjs(latestIngestDateConnection.connections[0].lastIngestedDate).format('D[-]MMM h:mm A')

  return {
    title: 'Lastest Ingest',
    integrations: [latestIngestDateConnection],
    text: formattedDate,
  }
}

export const getActionsExecutedStatCardData = (integrationConnections: IntegrationV2[]) => {
  if (!integrationConnections || integrationConnections.length === 0) {
    return {
      title: 'Actions Executed',
      integrations: [],
      text: '-',
    }
  }

  let totalActionsExecuted = 0

  const connectionsWithExecutedActions = integrationConnections.reduce((acc, integration) => {
    const totalActions = integration.actions?.total || 0
    if (totalActions > 0) {
      totalActionsExecuted += totalActions
      acc.push(integration)
    }
    return acc
  }, [] as IntegrationV2[])

  const formattedNumber = formatNumber({
    number: totalActionsExecuted,
    format: DataFormatEnum.CurrencyKMB,
    decimal: 1,
  }).slice(1)

  return {
    title: 'Actions Executed',
    integrations: connectionsWithExecutedActions,
    text: connectionsWithExecutedActions.length > 0 ? formattedNumber : '-',
  }
}

export const getStatusIcon = (status: string) => {
  switch (status) {
    case 'Live':
      return <IconCheckCircle />
    case 'Processing':
      return <IconProcessing />
    default:
      return <IconInfo />
  }
}

export const groupIntegrationsByCategory = (integrations: IntegrationV2[]) => {
  if (!integrations) return {}
  return integrations.reduce(
    (acc, integration) => {
      if (!acc[integration.category]) {
        acc[integration.category] = []
      }
      acc[integration.category].push(integration)
      return acc
    },
    {} as Record<string, IntegrationV2[]>,
  )
}

export const countStatusByCategory = (integrations: IntegrationV2[]) => {
  if (!integrations) return {}
  return integrations.reduce(
    (acc, integration) => {
      if (!acc[integration.category]) {
        acc[integration.category] = {
          Live: 0,
          Processing: 0,
          Error: 0,
        }
      }
      if (!integration.connections) return acc
      integration.connections.forEach((connection) => {
        acc[integration.category][connection.status]++
      })
      return acc
    },
    {} as Record<string, Record<string, number>>,
  )
}

export const getIntegrationsWithConnections = (integrations: IntegrationV2[]) => {
  if (!integrations) return []
  return integrations.filter((integration) => integration.connections && integration.connections.length)
}
