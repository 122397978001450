import type { IntegrationV2 } from 'models/Integration.model'

export const demoGetAllIntegrations = [
  {
    userLogin: true,
    oauth: true,
    iconUrl: 'https://s3.amazonaws.com/images.tray.io/static/service/icons/a8ff6c9514c35c853b350382534fb624.png',
    id: 'salesforce',
    name: 'Salesforce',
    sortOrder: 0,
    connections: [
      {
        solutionInstanceIds: {
          actionsSolutionInstanceId: 'df91dca7-5943-41f8-948d-843d5712da39',
          onboardingSolutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
        },
        app: 'salesforce',
        connectionDate: 1677254320908,
        userEmail: 'nisarg+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Salesforce account. - STG",
        isActive: true,
        id: '09a01cf8-6e3d-4d68-aa4d-168382a9d50f',
      },
    ],
    status: '1 connection',
    connectionDate: 1677254320908,
  },
  {
    userLogin: true,
    oauth: true,
    iconUrl: 'https://s3.amazonaws.com/images.tray.io/static/service/icons/01260647de9803dd69df3ecc34a8d908.png',
    id: 'zendesk',
    name: 'Zendesk',
    sortOrder: 1,
    connections: [
      {
        solutionInstanceIds: {
          actionsSolutionInstanceId: '73d70b2f-d715-495b-bd5e-5041d73aab4c',
          onboardingSolutionInstanceId: '20abba59-c852-4e51-b077-0c20378d4cf6',
        },
        app: 'zendesk',
        connectionDate: 1677276790150,
        userEmail: 'nisarg+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Zendesk account",
        isActive: true,
        id: '05dcd2a7-54a2-4927-a0e3-8f905c451fa1',
      },
    ],
    status: '1 connection',
    connectionDate: 1677276790150,
  },
  {
    userLogin: true,
    oauth: true,
    iconUrl: 'https://s3.amazonaws.com/images.tray.io/artisan/icons/08c8c3a0b5d92627f24fed878afd8325.png',
    id: 'snowflake',
    name: 'Snowflake',
    sortOrder: 2,
    connections: [
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '3980e36b-ecf8-4167-aed6-a1694f8d303f',
        },
        app: 'snowflake',
        connectionDate: 1697832379461,
        userEmail: 'josh+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: false,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: '0a34e672-a5df-49fb-97e9-9124124076e5',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '5150d65d-597b-43c4-8fb5-8aae601aa6c9',
        },
        app: 'snowflake',
        connectionDate: 1679521481778,
        userEmail: 'nisarg+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: true,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: '1108bbbb-1d76-4fd5-9ec1-0ce5ed72fac0',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '472db7cb-3bfc-4f64-87d7-ec947a2e2ad0',
        },
        app: 'snowflake',
        connectionDate: 1677265967219,
        userEmail: 'nisarg+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: true,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '54f54c0c-b6be-4412-8c65-56675b09282c',
        },
        app: 'snowflake',
        connectionDate: 1692230009538,
        userEmail: 'wenduo+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: false,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: '5c6a9d77-7357-410e-9e25-57237bf95238',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '989a0f03-ed5c-4134-b613-1fc937cb86c8',
        },
        app: 'snowflake',
        connectionDate: 1679521505599,
        userEmail: 'paarth+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: true,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: '5c8429b0-add2-4aee-b70b-8e5e637152b1',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '05b38ad3-7860-4458-aaeb-aa9d797d6304',
        },
        app: 'snowflake',
        connectionDate: 1679521488432,
        userEmail: 'nisarg+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: true,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: '629de329-b91c-4f16-aa0d-e4848e292049',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: 'a85ced30-2519-4ae6-8bc1-0cfefc0f1788',
        },
        app: 'snowflake',
        connectionDate: 1679607130852,
        userEmail: 'paarth+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: true,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: '7b8efbf3-03ac-4a52-852d-ae202cb4ec33',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '7466d037-d9bf-4f14-9a0d-1546a918c3b2',
        },
        app: 'snowflake',
        connectionDate: 1697832403911,
        userEmail: 'josh+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: false,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: 'd48f22ad-724e-476a-b736-52be456a4bce',
      },
      {
        solutionInstanceIds: {
          onboardingSolutionInstanceId: '67ae3501-b6b5-417b-a40a-6a52dae643a6',
        },
        app: 'snowflake',
        connectionDate: 1684517163265,
        userEmail: 'josh+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Snowflake account",
        isActive: false,
        authenticationId: '32ddbdd2-d560-4c6a-945c-7f56351d7736',
        id: 'ed1dad13-e639-439e-beab-ae347c569944',
      },
    ],
    status: '9 connections',
    connectionDate: 1697832403911,
  },
  {
    userLogin: true,
    oauth: true,
    iconUrl: 'https://s3.amazonaws.com/images.tray.io/artisan/icons/c25033a972715dbbdb7e73ac79261230.png',
    id: 'slack',
    name: 'Slack',
    sortOrder: 3,
    connections: [],
    status: 'Needs attention',
    connectionDate: 0,
  },
  {
    userLogin: true,
    oauth: true,
    iconUrl: 'https://s3.amazonaws.com/images.tray.io/artisan/icons/gainsight.png',
    id: 'gainsight',
    name: 'Gainsight',
    sortOrder: '4',
    connections: [
      {
        solutionInstanceIds: {
          actionsSolutionInstanceId: '19082d00-4f18-49e2-a2a1-012d7c50dc2a',
          onboardingSolutionInstanceId: '0b671e7e-1fe7-4d3d-bcf4-10a420464f4f',
        },
        app: 'gainsight',
        connectionDate: 1677268867262,
        userEmail: 'nisarg+magnifydemo@magnify.io',
        tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
        groupId: '',
        status: 'Connected',
        visibility: 'tenant',
        oauth: true,
        name: "MagnifyDemo's Gainsight account",
        isActive: true,
        id: '01d66ba4-d046-48c5-8772-e8b0f89e6467',
      },
    ],
    status: '1 connection',
    connectionDate: 1677268867262,
  },
  {
    userLogin: true,
    oauth: true,
    iconUrl: 'https://s3.amazonaws.com/images.tray.io/static/service/icons/marketo.png',
    id: 'marketo',
    name: 'Marketo',
    sortOrder: 5,
    connections: [],
    status: 'Needs attention',
    connectionDate: 0,
  },
  {
    iconUrl: 'https://s3.amazonaws.com/images.tray.io/artisan/icons/hubspot.png',
    id: 'hubspot',
    name: 'Hubspot',
    sortOrder: '6',
    connections: [],
    status: 'Needs attention',
    connectionDate: 0,
  },
]

export const demoGetAllNewIntegrations = [
  {
    key: 'hubspot',
    displayName: 'Hubspot',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {
          type: 'object',
          required: ['start_date'],
          properties: {
            start_date: {
              type: 'string',
              title: 'Start Date',
              description:
                "The UTC date and time from which you'd like to replicate data, in the format YYYY-MM-DDT00:00:00Z. All data generated after this date will be replicated.",
              examples: ['2020-10-15T00:00:00Z'],
              pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$',
              format: 'date-time',
              order: 0,
            },
          },
        },
      ],
      required: [],
    },
  },
  {
    key: 'intercom',
    displayName: 'Intercom',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {
          type: 'object',
          required: ['start_date'],
          properties: {
            start_date: {
              type: 'string',
              title: 'Start Date',
              description:
                "The UTC date and time from which you'd like to replicate data, in the format YYYY-MM-DDT00:00:00Z. All data generated after this date will be replicated.",
              examples: ['2020-10-15T00:00:00Z'],
              pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$',
              pattern_descriptor: 'YYYY-MM-DDTHH:mm:ssZ',
              format: 'date-time',
              order: 0,
            },
          },
        },
      ],
      required: [],
    },
  },
  {
    key: 'pendo',
    displayName: 'Pendo',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {},
      ],
      required: [],
    },
  },
  {
    key: 'snowflake',
    displayName: 'Snowflake',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['username', 'password'],
          properties: {
            username: {
              description: 'The username for connecting to this platform.',
              type: 'string',
              title: 'Username',
              order: 0,
              minLength: 1,
            },
            password: {
              description: 'The password associated with the username for this platform.',
              type: 'string',
              title: 'Password',
              order: 1,
              minLength: 1,
            },
          },
        },
        {
          type: 'object',
          required: ['host', 'role', 'warehouse', 'database'],
          properties: {
            host: {
              description:
                'The host domain of the snowflake instance (must include the account, region, cloud environment, and end with snowflakecomputing.com).',
              examples: ['account-name.us-east-2.aws.snowflakecomputing.com'],
              type: 'string',
              title: 'Account Name',
              order: 0,
              minLength: 1,
              format: 'uri',
            },
            role: {
              description: 'The role you created for Magnify to access Snowflake.',
              examples: ['MAGNIFY_ROLE'],
              type: 'string',
              title: 'Role',
              order: 1,
              minLength: 1,
            },
            warehouse: {
              description: 'The warehouse you created for Magnify to access data.',
              examples: ['MAGNIFY_WAREHOUSE'],
              type: 'string',
              title: 'Warehouse',
              order: 2,
              minLength: 1,
            },
            database: {
              description: 'The database you created for Magnify to access data.',
              examples: ['MAGNIFY_DATABASE'],
              type: 'string',
              title: 'Database',
              order: 3,
              minLength: 1,
            },
            schema: {
              description: 'The source Snowflake schema tables. Leave empty to access tables from multiple schemas.',
              examples: ['MAGNIFY_SCHEMA'],
              type: 'string',
              title: 'Schema',
              order: 4,
            },
          },
        },
      ],
      required: [],
    },
  },
  {
    key: 'zendesk-support',
    displayName: 'Zendesk',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {
          type: 'object',
          required: ['subdomain', 'email'],
          properties: {
            subdomain: {
              description:
                'This is your unique Zendesk subdomain that can be found in your account URL. For example, in https://MY_SUBDOMAIN.zendesk.com/, MY_SUBDOMAIN is the value of your subdomain.',
              examples: ['MY_SUBDOMAIN'],
              type: 'string',
              title: 'Subdomain',
              order: 0,
              minLength: 1,
            },
            email: {
              title: 'Email',
              type: 'string',
              description: 'The user email for your Zendesk account.',
              format: 'email',
              order: 2,
              minLength: 1,
            },
            start_date: {
              type: 'string',
              title: 'Start Date',
              description:
                "The UTC date and time from which you'd like to replicate data, in the format YYYY-MM-DDT00:00:00Z. All data generated after this date will be replicated.",
              examples: ['2020-10-15T00:00:00Z'],
              pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$',
              pattern_descriptor: 'YYYY-MM-DDTHH:mm:ssZ',
              format: 'date-time',
              order: 2,
            },
          },
        },
      ],
      required: [],
    },
  },
]

export const demoGetAllIntegrationsV2: { platforms: IntegrationV2[] } = {
  platforms: [
    {
      id: 'gainsight',
      displayName: 'Gainsight',
      canAccess: true,
      isConnected: true,
      category: 'Customer Success Platform',
      connections: [
        {
          connectionId: 'gainsight-473648',
          initialConnectionDate: 1725174754000,
          lastIngestedDate: 1725174754000,
          status: 'Live',
          errorMsg: '',
        },
        {
          connectionId: 'gainsight-473578',
          initialConnectionDate: 1725192094000,
          lastIngestedDate: 1725192094000,
          status: 'Live',
          errorMsg: '',
        },
      ],
      actions: {
        total: 345700,
        actions: {
          'Create Timeline Event': {
            total: 345700,
          },
        },
      },
    },
    {
      id: 'intercom',
      displayName: 'Intercom',
      canAccess: true,
      isConnected: true,
      category: 'Support',
      connections: [
        {
          connectionId: 'intercom-473648',
          initialConnectionDate: 1725291754000,
          lastIngestedDate: 1725291754000,
          status: 'Live',
          errorMsg: '',
        },
      ],
      actions: {
        total: 12400,
        actions: {
          'Create Ticket': {
            total: 12400,
          },
        },
      },
    },
    {
      id: 'zendesk-support',
      displayName: 'Zendesk',
      canAccess: true,
      isConnected: false,
      category: 'Support',
    },
    {
      id: 'hubspot',
      displayName: 'Hubspot',
      canAccess: true,
      isConnected: false,
      category: 'Sales CRM',
      connections: [
        {
          connectionId: 'hubspot-473648',
          initialConnectionDate: 1725469774000,
          lastIngestedDate: 1725469774000,
          status: 'Processing',
        },
      ],
    },
    {
      id: 'pendo',
      displayName: 'Pendo',
      canAccess: true,
      isConnected: false,
      category: 'Telemetry / Data Warehouse',
      connections: [
        {
          connectionId: 'pendon-473648',
          initialConnectionDate: 1725532894000,
          lastIngestedDate: 1725532894000,
          status: 'Error',
          errorMsg: 'Authentication failed',
        },
      ],
    },
    {
      id: 'salesforce',
      displayName: 'Salesforce',
      canAccess: true,
      isConnected: true,
      category: 'Sales CRM',
      connections: [
        {
          connectionId: 'salesforce-473648',
          initialConnectionDate: 1725719554000,
          lastIngestedDate: 1725719554000,
          status: 'Live',
        },
      ],
      actions: {
        total: 12400,
        actions: {
          'Create Ticket': {
            total: 12400,
          },
        },
      },
    },
    {
      id: 'marketo',
      displayName: 'Marketo',
      canAccess: true,
      isConnected: true,
      category: 'Marketing',
      connections: [
        {
          connectionId: 'marketo-473648',
          initialConnectionDate: 1725008074000,
          lastIngestedDate: 1725008074000,
          status: 'Live',
          errorMsg: '',
        },
      ],
      actions: {
        total: 12400,
        actions: {
          'Create Ticket': {
            total: 12400,
          },
        },
      },
    },
    {
      id: 'mongodb',
      displayName: 'MongoDB',
      canAccess: true,
      category: 'Telemetry / Data Warehouse',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'netsuite',
      displayName: 'NetSuite',
      canAccess: true,
      category: 'Other',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'redshift',
      displayName: 'Redshift',
      canAccess: true,
      category: 'Telemetry / Data Warehouse',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'slack',
      displayName: 'Slack',
      canAccess: true,
      category: 'Other',
      connections: [],
      isConnected: true,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'snowflakedb',
      displayName: 'Snowflake',
      canAccess: true,
      category: 'Telemetry / Data Warehouse',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'chorusai',
      displayName: 'Chorus AI',
      canAccess: true,
      category: 'Other',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
  ],
}
