import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { INITIAL_DSL } from 'components/MotionTarget/MotionTargetSettings'
import { ACTIVE_MOTIONS_NO } from 'configs'
import { clone } from 'services/Utils/misc'
import { motionCleanUpBeforeUpdate, mapApiResponseToStore } from 'services/Utils/motionHelpers/motionHelpers.utils'
import type { ChildStore } from 'store/StoreTypes'

import type { Node } from 'react-flow-renderer'

import type {
  Action,
  DroppedAction,
  EdgeToDrop,
  SegmentBuilderData,
  SegmentExportData,
  SegmentOutput,
} from 'models/motion/motionBuilder.model'
import type {
  InternalMotionExecutionOperationalStats,
  ABExperiment,
  CurrentMotion,
  Motion,
  MotionExecute,
  MotionIdentifiers,
  MotionSchedule,
  Motions,
  NewMotion,
  SegmentExport,
} from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'

export const initCurrentMotion: NewMotion = {
  tenantId: '1',
  goal: {},
  metrics: [],
  dsl: INITIAL_DSL,
  currState: MotionStateEnum.Draft,
  stateHistory: {},
  title: '',
  description: '',
  isActive: false,
  segmentTotals: [],
  segmentExports: [],
  experiment: null,
}

export class MotionStore implements ChildStore {
  isLoading = false
  displayEdgeDrops = false
  draggedToolBoxElement: Action | null = null
  dragOverElement: EdgeToDrop | null = null
  droppedElement: DroppedAction | null = null
  motions: Motions = { data: [], total: 0 }
  areMotionsFetchedSuccessfully = false
  currentMotion: CurrentMotion = null
  focusedNode: string | null = null
  goUp: boolean = false
  wasUp: boolean = false
  goDown: boolean = false
  currentNode: Node<SegmentBuilderData> = { id: '', position: { x: 0, y: 0 } }
  displayRemoveModal: boolean = false
  displayExecuteModal: boolean = false
  motionToExecute: Motion | null = null
  configPanelNode: string | null = null
  selectedDashboardActiveMotion: Motion | null = null
  isMotionLoading = false
  isAccountsLoading: boolean = false
  isSegmentExportsLoading: boolean = false
  segmentOutput: SegmentOutput = {
    message: '',
    segmentExports: [],
    status: 200,
    isLoading: false,
    isModalDisplayed: false,
  }
  executionOperationalStats: InternalMotionExecutionOperationalStats | null = null
  isInMotionReportingEnabled: boolean = false
  isSegmentBuilderEditDisabled: boolean = false

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      dashboardActiveMotions: computed,
      cumulativeMotions: computed,
      selectedDashboardActiveMotion: observable,
      setDashboardActiveMotion: action,
    })
  }

  reset = () => {
    this.isLoading = false
    this.displayEdgeDrops = false
    this.draggedToolBoxElement = null
    this.dragOverElement = null
    this.droppedElement = null
    this.motions = { data: [], total: 0 }
    this.areMotionsFetchedSuccessfully = false
    this.currentMotion = null
    this.focusedNode = null
    this.goUp = false
    this.wasUp = false
    this.goDown = false
    this.currentNode = { id: '', position: { x: 0, y: 0 } }
    this.displayRemoveModal = false
    this.displayExecuteModal = false
    this.motionToExecute = null
    this.configPanelNode = null
    this.selectedDashboardActiveMotion = null
    this.isMotionLoading = false
    this.isAccountsLoading = false
    this.isSegmentExportsLoading = false
    this.segmentOutput = { message: '', segmentExports: [], status: 200, isLoading: false, isModalDisplayed: false }
    this.executionOperationalStats = null
    this.isInMotionReportingEnabled = false
    this.isSegmentBuilderEditDisabled = false
    this.apiError = null
  }

  setDroppedElement = (element: DroppedAction | null) => {
    if (element) {
      this.droppedElement = {
        data: element.data,
        edgeToDrop: element.edgeToDrop,
      }
    } else {
      this.droppedElement = null
    }
  }

  setDragOverElement = (edgeToDrop: EdgeToDrop) => {
    this.dragOverElement = edgeToDrop
  }

  initCurrentMotion = (initMotion?: NewMotion | Motion) => {
    this.currentMotion = initMotion || initCurrentMotion
  }

  setDisplayEdgeDrops = (value: boolean) => {
    this.displayEdgeDrops = value
  }

  setDraggedToolBoxElement = (element: Action) => {
    this.draggedToolBoxElement = element
  }

  // Force refresh used in scenarios to prevent the user from having to manually refresh the entire web application
  getAll = async (forceRefresh: boolean = false, limit = 10, offset = 0): Promise<void> => {
    // If the Motions have already been fetched and cached, no need to request again
    if ((this.motions.data.length === 0 && !this.areMotionsFetchedSuccessfully) || forceRefresh) {
      this.isLoading = true
      try {
        const motionsApiResponse = await API.motions.getAll(limit, offset)
        runInAction(() => {
          this.motions = mapApiResponseToStore(motionsApiResponse, limit, offset, this.motions)
          this.areMotionsFetchedSuccessfully = true
        })
      } catch (error: unknown) {
        this.setApiError(error as CoreAPIErrorResponse)
      } finally {
        runInAction(() => {
          this.isLoading = false
        })
      }
    }
  }

  getPaginatedMotions = async (limit = 10, offset = 0): Promise<void> => {
    this.isLoading = true
    try {
      const motionsApiResponse = await API.motions.getAll(limit, offset)
      runInAction(() => {
        this.motions = mapApiResponseToStore(motionsApiResponse, limit, offset, this.motions)
        this.isLoading = false
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  get cumulativeMotions(): Motion[] {
    return this.motions.data.flatMap((motion) => motion.items)
  }

  get motionSegmentTotals() {
    return this.currentMotion?.segmentTotals?.[0]
  }

  get dashboardActiveMotions(): Motion[] {
    // Only return the most recent 3 active Motions
    const filteredMotions = this.cumulativeMotions
      .filter((motion) => [MotionStateEnum.Scheduled, MotionStateEnum.Executing].includes(motion.currState))
      .slice(0, ACTIVE_MOTIONS_NO)
      .map((motion) => {
        return {
          ...motion,
          ...(motion.schedule && { schedule: JSON.parse(motion.schedule) as string }),
        }
      })

    runInAction(() => {
      this.selectedDashboardActiveMotion = filteredMotions[0]
    })

    return filteredMotions
  }

  setDashboardActiveMotion = (selectedMotionId: string | null) => {
    if (selectedMotionId === null || this.selectedDashboardActiveMotion?.playbookId === selectedMotionId) {
      this.selectedDashboardActiveMotion = null
    } else {
      this.selectedDashboardActiveMotion = this.dashboardActiveMotions.filter(
        (aj) => aj.playbookId === selectedMotionId,
      )[0]
    }
  }

  get = async (data: MotionIdentifiers): Promise<any> => {
    this.isLoading = true
    try {
      const newMotion = await API.motions.get(data)

      runInAction(() => {
        this.currentMotion = newMotion
        this.isLoading = false
      })

      return newMotion
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  post = async (motion: Motion): Promise<Motion | undefined> => {
    this.isMotionLoading = true
    try {
      const newMotion = await API.motions.post(motion)

      runInAction(() => {
        if (!(newMotion instanceof Error)) {
          this.initCurrentMotion({ ...newMotion, dsl: motion.dsl })
        }
        this.isMotionLoading = false
      })
      return newMotion
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isMotionLoading = false
      })
    }
    return
  }

  update = async (motion: Motion, isEditSegmentWhilstExecuting: boolean = false): Promise<Motion | undefined> => {
    this.isMotionLoading = true
    let updatedMotion: Motion
    try {
      const cleanMotion = motionCleanUpBeforeUpdate(clone(motion))
      updatedMotion = await API.motions.update(cleanMotion, isEditSegmentWhilstExecuting)

      runInAction(() => {
        if (!(updatedMotion instanceof Error)) {
          this.initCurrentMotion({ ...updatedMotion, dsl: motion.dsl })
        }
        this.isMotionLoading = false
      })
      return updatedMotion
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isMotionLoading = false
      })
    }
    return
  }

  archive = async (motion: MotionIdentifiers): Promise<void> => {
    try {
      await API.motions.archive(motion)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  clone = async (motion: MotionIdentifiers): Promise<void> => {
    this.isLoading = true
    try {
      await API.motions.clone(motion)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  executeNow = async (motion: MotionIdentifiers): Promise<void> => {
    this.isLoading = true
    try {
      await API.motions.executeNow(motion)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  cancel = async (motion: MotionIdentifiers): Promise<void> => {
    try {
      await API.motions.cancel(motion)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  execute = async (motion: MotionExecute): Promise<void> => {
    this.isLoading = true
    try {
      await API.motions.execute(motion)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
      throw error
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  setFocusedNode = (node: string | null) => {
    this.focusedNode = node
  }

  setConfigPanelNode(nodeId: string | null) {
    this.configPanelNode = nodeId
  }

  setGoUp = (value: boolean) => {
    this.goUp = value
  }

  setWasUp = (value: boolean) => {
    this.wasUp = value
  }

  setGoDown = (value: boolean) => {
    this.goDown = value
  }

  createOrUpdateMotionSchedule = async (scheduleData: MotionSchedule) => {
    this.isLoading = true
    try {
      await API.motions.createOrUpdateMotionSchedule(scheduleData)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  deleteMotionSchedule = async (motion: Motion) => {
    this.isLoading = true
    try {
      await API.motions.deleteMotionSchedule(motion)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  setCurrentNode = (node: Node<SegmentBuilderData>) => {
    this.currentNode = node
  }

  setDisplayRemoveModal = (node: boolean) => {
    this.displayRemoveModal = node
  }

  setDisplayExecuteModal = (isDisplayed: boolean, currentMotionToExecute: Motion | null = null) => {
    this.displayExecuteModal = isDisplayed
    this.motionToExecute = currentMotionToExecute
  }

  setDisplaySegmentOutputModal = (isDisplayed: boolean) => {
    this.segmentOutput.isModalDisplayed = isDisplayed
  }

  generateSegmentOutput = async (segmentExportData: SegmentExportData) => {
    this.segmentOutput.isLoading = true
    const { message, segmentExports, status } = await API.motions.generateSegmentOutput(segmentExportData)
    const result = {
      ...this.segmentOutput,
      message,
      segmentExports,
      status,
      isLoading: false,
    }

    runInAction(() => {
      this.segmentOutput = result
    })

    return result
  }

  // Limit is set to 1000 by default to prevent going over the 1mb DynamoDB query limit (1000 items is ~0.5mb)
  getInternalMotionOperationalStats = async (data: MotionIdentifiers, limit = 1000, offset = 0): Promise<any> => {
    this.isLoading = true
    try {
      const operationalStats = await API.motions.getMotionExecutionStats(data, limit, offset)

      runInAction(() => {
        this.executionOperationalStats = operationalStats
        this.isLoading = false
      })

      return operationalStats
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getSegmentExports = async (motionId: string, version: number): Promise<any> => {
    this.isSegmentExportsLoading = true
    try {
      const { segmentExports } = await API.motions.getSegmentExports(motionId, version)

      runInAction(() => {
        if (this.currentMotion) {
          this.currentMotion.segmentExports = segmentExports
        }
        this.isSegmentExportsLoading = false
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isSegmentExportsLoading = false
      })
    }
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }

  doesContainExperiment = () => {
    return Object.keys(this.currentMotion?.experiment?.dsl || {}).length > 0
  }

  // To show spinner fr 1.5 seconds to simulate an api call for demo purposes
  tempUpdate = () => {
    this.isMotionLoading = true

    runInAction(() => {
      setTimeout(() => {
        this.isMotionLoading = false
      }, 1500)
    })
  }

  // Temporary until we can save experiment to api
  setExperiment = (experiment: ABExperiment) => {
    runInAction(() => {
      if (this.currentMotion) {
        this.currentMotion.experiment = experiment
      }
    })
  }

  /**
   * Saves the segment export response locally so that it can be displayed in the 'processing' status.
   * When the motion is fetched again, the segment exports will be fetched from the API.
   *
   * @param segmentExports - An array of segment exports.
   */
  setSegmentExportsLocally = (segmentExports: SegmentExport[]) => {
    runInAction(() => {
      if (this.currentMotion) {
        this.currentMotion.segmentExports = segmentExports
      }
    })
  }

  setIsInMotionReportingEnabled = (isInMotionReportingEnabled: boolean) => {
    runInAction(() => {
      this.isInMotionReportingEnabled = isInMotionReportingEnabled
    })
  }

  setIsSegmentBuilderEditDisabled = (isSegmentBuilderEditDisabled: boolean) => {
    runInAction(() => {
      this.isSegmentBuilderEditDisabled = isSegmentBuilderEditDisabled
    })
  }
}
