import { action, makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type {
  SalesforceOpportunity,
  Connection,
  Integration,
  ConnectionPost,
  MarketoAction,
  IntegrationNew,
  IntegrationParam,
  IntegrationV2,
} from 'models/Integration.model'

export class IntegrationsStore implements ChildStore {
  integrations: Integration[] = []
  integrationsNew: IntegrationNew[] = []
  isLoading = false
  isLoadingActions = false
  currentIntegration: Integration | null = null
  currentIntegrationNew: IntegrationNew | null = null
  actions: SalesforceOpportunity[] = []
  marketoData: MarketoAction = { campaigns: [], leads: [] }
  integrationsV2: IntegrationV2[] = []

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      integrations: observable,
      integrationsNew: observable,
      integrationsV2: observable,
      isLoading: observable,
      currentIntegration: observable,
      currentIntegrationNew: observable,
      set: action,
      get: action,
      remove: action,
      post: action,
    })
  }

  reset = () => {
    this.integrations = []
    this.integrationsNew = []
    this.integrationsV2 = []
    this.isLoading = false
    this.isLoadingActions = false
    this.currentIntegration = null
    this.currentIntegrationNew = null
    this.actions = []
    this.marketoData = { campaigns: [], leads: [] }
    this.apiError = null
  }

  set({ integrations, isLoading }: { integrations: Integration[]; isLoading: boolean }) {
    this.integrations = integrations
    this.isLoading = isLoading
  }

  setLoading = (value: boolean) => {
    this.isLoading = value
  }

  setCurrentIntegration = (integration: Integration | null) => {
    this.currentIntegration = integration
  }

  get = async (id: string): Promise<void> => {
    this.isLoading = true
    try {
      const integration = await API.integrations.get(id)
      runInAction(() => {
        this.currentIntegration = integration
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getAll = async (): Promise<void> => {
    this.isLoading = true
    try {
      const integrations = await API.integrations.getAll()
      runInAction(() => {
        this.integrations = integrations
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getActions = async (app: string, connectionId: string): Promise<void> => {
    this.isLoadingActions = true
    const actions = await API.integrations.getActionsData(app, connectionId)

    runInAction(() => {
      switch (app) {
        case 'salesforce':
          this.actions = actions as SalesforceOpportunity[]
          break
        case 'marketo':
          this.marketoData = actions as MarketoAction
          break

        default:
          break
      }
      this.isLoadingActions = false
    })
  }

  removeConnection = async (connectionId: string): Promise<void> => {
    this.isLoading = true
    await API.integrations.remove(connectionId)
    // TODO: This code isn't reachable, removeing an integration returns nothing: && removedSource.message === 'SUCCESS!'
    if (this.currentIntegration) {
      const sourceIndex = this.currentIntegration.connections.findIndex((connection) => connection.id === connectionId)
      this.currentIntegration.connections.splice(sourceIndex, 1)
    }

    this.isLoading = false
  }

  remove = async (id: string): Promise<void> => {
    this.isLoading = true
    const removedSource = await API.integrations.remove(id)
    const sourceIndex = this.integrations.findIndex((source) => source.id === id)
    if (removedSource) {
      this.integrations.splice(sourceIndex, 1)
    }
    this.isLoading = false
  }

  post = async (id: string, integration: ConnectionPost): Promise<any> => {
    this.isLoading = true
    const newIntegration = await API.integrations.post(id, integration)
    runInAction(() => {
      this.isLoading = false
    })
    return newIntegration
  }

  update = async (app: string, connectionId: string, data: Integration): Promise<Integration | undefined> => {
    try {
      const updatedIntegration = await API.integrations.update(app, connectionId, data)

      const keys = Object.keys(data)
      const isOnlyUpdatingName = keys.length === 1 && keys[0] === 'name'
      if (isOnlyUpdatingName && data.name && !(updatedIntegration instanceof Error)) {
        runInAction(() => {
          if (this.currentIntegration) {
            const indexOfupdatedConnection = this.currentIntegration.connections.findIndex(
              (connection: Connection) => connection.id === connectionId,
            )
            if (this.currentIntegration.connections[indexOfupdatedConnection].name && data.name) {
              this.currentIntegration.connections[indexOfupdatedConnection].name = data.name
            }
          }
        })
      }
      return updatedIntegration
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }

  getNew = async (id: string, options?: IntegrationParam): Promise<void> => {
    this.isLoading = true
    try {
      const integration = await API.integrations.getNew(id, options)
      runInAction(() => {
        this.currentIntegrationNew = integration
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getAllNew = async (): Promise<void> => {
    this.isLoading = true
    try {
      const integrations = await API.integrations.getAllNew()
      runInAction(() => {
        this.integrationsNew = integrations
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  postNew = async (id: string, data: any): Promise<any> => {
    this.isLoading = true

    try {
      const newIntegration = await API.integrations.postNew(id, data)
      return newIntegration
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getIntegrationsV2 = async (): Promise<void> => {
    this.isLoading = true
    try {
      const { platforms } = await API.integrations.getIntegrationsV2()
      runInAction(() => {
        this.integrationsV2 = platforms
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }
}
